
.App {
  background: url('./assets/BG.png') no-repeat;
  background-size: cover;
}

.Home {
  background: url('./assets/home-bg.png') no-repeat top;
  background-size:cover;
}
